import { Specialty } from 'src/shared/types';

export interface SpecialtyUI extends Specialty {
  adultGeneral: number;
  childrenGeneral: number;
  cheapestPrice: number;
}

export const mapSpecialties = (specialies: Specialty[]): SpecialtyUI[] => {
  const formattedSpecialties = specialies.map((specialty) => {
    let adultGeneral = 1;
    let childrenGeneral = 1;

    const cheapestPrice = specialty.minServiceCost;
    return { ...specialty, adultGeneral, childrenGeneral, cheapestPrice };
  });

  return formattedSpecialties;
};
