/* eslint-disable @typescript-eslint/no-explicit-any */
export const hexToRgb = (hex: any) =>
  hex
    .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m: any, r: any, g: any, b: any) => '#' + r + r + g + g + b + b)
    .substring(1)
    .match(/.{2}/g)
    .map((x: any) => parseInt(x, 16));

export const groupBy = <T>(array: T[], predicate: (value: T, index: number, array: T[]) => string) =>
  array.reduce((acc, value, index, array) => {
    (acc[predicate(value, index, array)] ||= []).push(value);
    return acc;
  }, {} as { [key: string]: T[] });
