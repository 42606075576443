import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface FilterState {
  isChildren?: boolean;
  branchId?: string;
  childrenAge?: number;
}

const initialState: FilterState = {
  isChildren: false,
};

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setIsChildren: (state, action: PayloadAction<boolean>) => {
      state.isChildren = action.payload;
    },
    setChildrenAge: (state, action: PayloadAction<number>) => {
      state.childrenAge = action.payload;
    },
    setBranch: (state, action: PayloadAction<string>) => {
      state.branchId = action.payload;
    },
    resetFilters: (state) => {
      state.isChildren = false;
      state.branchId = undefined;
    },
  },
});

export const { setIsChildren, setChildrenAge, setBranch, resetFilters } = filterSlice.actions;

export const filterReducer = filterSlice.reducer;
