import { CaretRight } from 'src/shared/icons';
import { Col, List, Row, Typography } from 'antd';
import { CustomTag } from 'src/shared/components/CustomTag/CustomTag';
import { Doctor } from 'src/shared/types';
import { DoctorPhoto } from 'src/shared/components/DoctorPhoto/DoctorPhoto';
import { ListComponent } from 'src/shared/components/List/List';
import { goToPage } from 'src/shared/redux/routing/routingSlice';
import { setDoctor, setDoctorId } from 'src/shared/redux/appointment/appointmentSlice';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import React, { useEffect, useState } from 'react';
import { useGetDoctorsSchedulesQuery } from '../../../shared/redux/api/apiSlice';
import { CustomSpinner } from '../../../shared/components/CustomSpinner/CustomSpinner';
import styles from './styles.module.scss';

interface DoctorListProps {
  doctors: Doctor[];
}

export const DoctorList = ({ doctors }: DoctorListProps) => {
  const dispatch = useAppDispatch();
  const isChildren = useAppSelector((state) => state.filter.present.isChildren);
  const childrenAge = useAppSelector((state) => state.filter.present.childrenAge);
  const [filteredDoctors, setFilteredDoctors] = useState<Doctor[]>();
    const {data: doctorsSchedules, isLoading} = useGetDoctorsSchedulesQuery({dateFrom: new Date().toDateString(),
        dateTo: new Date(Date.now() + 6.048e8 * 2).toDateString(),
        ids: doctors?.map(doctor => doctor.specialitiesTags.map(specialty => ({idFerSpeciality: specialty?.idFerSpeciality, idDoctor: doctor.idMilaDoctor}))).flat()}, {skip: !doctors});

  useEffect(() => {
    if (isChildren) {
      setFilteredDoctors(
        doctors
          .map((doctor) => ({
            ...doctor,
            schedules: doctorsSchedules?.filter(x => x.idDoctor === doctor.idMilaDoctor).map(x => x.schedule)
                .filter(
              (schedule) =>
                schedule.isChildrenDoctor &&
                childrenAge !== undefined &&
                schedule.serveChildrenFrom <= childrenAge &&
                schedule.serveChildrenTo >= childrenAge,
            ) ?? []
          }))
          .filter((item) => item.schedules.length > 0),
      );
    } else {
      setFilteredDoctors(
        doctors
          .map((doctor) => ({
            ...doctor,
              schedules: doctorsSchedules?.filter(x => x.idDoctor === doctor.idMilaDoctor)
                  .map(x => x.schedule)
                  .filter((schedule) => schedule.isAdultDoctor) ?? []
          }))
          .filter((item) => item.schedules.length > 0),
      );
    }
  }, [doctors, isChildren, childrenAge, doctorsSchedules]);

  const handleMenuClick = (item: Doctor) => {
    dispatch(setDoctor(item));
    dispatch(setDoctorId(item.idMilaDoctor));
    dispatch(goToPage('aboutDoctor'));
  };

  return (isLoading ? <CustomSpinner /> :
    <ListComponent
      containerClass={styles.ListContainer}
      data={filteredDoctors?.length && filteredDoctors?.length > 0 ? filteredDoctors : []}
      renderFunc={(item) => (
        <List.Item onClick={() => handleMenuClick(item)} className={styles.SearchItem}>
          <Row className={styles.SearchRow}>
            <Col>
              <DoctorPhoto src={item.photoId} className={styles.DoctorPhoto} />
            </Col>
            <Col className={styles.TextCol}>
              <Typography className={styles.Text}>{item.fio}</Typography>
              <Row className={styles.TagRow}>
                {item.specialitiesTags.length > 0 &&
                  item.specialitiesTags.map((tag) => (
                    <CustomTag key={tag?.idFerSpeciality}>{tag.specialityName}</CustomTag>
                  ))}
              </Row>
            </Col>
          </Row>
          <CaretRight className={'IconFill'} />
        </List.Item>
      )}
    />
  );
};
