import { Button, Col, Image, Row, Typography } from 'antd';
import { CloseButton } from 'src/shared/icons';
import { Header } from 'antd/lib/layout/layout';
import { useAppSelector } from 'src/hooks';
import { useGetWidgetInfoQuery } from 'src/shared/redux/api/apiSlice';
import React from 'react';
import dayjs from 'dayjs';
import styles from './styles.module.scss';

export const HeaderComponent = ({ page }: { page: string }) => {
  const { data: widgetInfo } = useGetWidgetInfoQuery('');
  const specialty = useAppSelector((state) => state.appointment.present.specialty);
  const doctor = useAppSelector((state) => state.appointment.present.doctor);
  const slot = useAppSelector((state) => state.appointment.present.slot);

  const handleCloseClick = () => {
    window.top?.postMessage('CloseWidget', '*');
  };

  return (
    <Header className={styles.Header}>
      <Row className={styles.HeaderRow}>
        <Col className={styles.LogoCol}>
          <Row>
            {widgetInfo?.idLogoFile && (
              <Image
                preview={false}
                className={styles.Logo}
                src={`${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${widgetInfo?.idLogoFile}`}
              />
            )}
            <Typography className={styles.Clinic}>{widgetInfo?.title ?? ''}</Typography>
          </Row>
        </Col>
        {page !== 'home' && (
          <Col>
            <Row className={styles.BreadcrumbsRow}>
              <Typography
                className={`${styles.BreadcrumbsText} ${page === 'appointment' ? styles.BreadcrumbsTextActive : ''}`}
              >
                {specialty ? specialty.milaSpecialityName : 'Выбор специализации'}
              </Typography>
              <Typography className={styles.VerticalDivider}>|</Typography>
              <Typography
                className={`${styles.BreadcrumbsText} ${
                  page === 'doctors' || page === 'aboutDoctor' ? styles.BreadcrumbsTextActive : ''
                }`}
              >
                {doctor
                  ? `${doctor.fio} ${
                      slot
                        ? `${dayjs(slot.visitStart).format('D MMM')} в ${dayjs(slot.visitStart).format('HH:mm')}`
                        : ''
                    } `
                  : 'Выбор врача и времени'}
              </Typography>
              <Typography className={styles.VerticalDivider}>|</Typography>
              <Typography
                className={`${styles.BreadcrumbsText} ${page === 'signUp' ? styles.BreadcrumbsTextActive : ''}`}
              >
                Контактные данные
              </Typography>
            </Row>
          </Col>
        )}
        <Button onClick={handleCloseClick} className={styles.CloseButton}>
          <CloseButton className={' IconStroke'} />
        </Button>
      </Row>
    </Header>
  );
};
