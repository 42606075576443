import { Card, Col, Typography } from 'antd';
import { useGetWidgetInfoQuery } from 'src/shared/redux/api/apiSlice';
import Link from 'antd/es/typography/Link';
import React, { useMemo } from 'react';
import styles from './styles.module.scss';

export const DiscountBanner = () => {
  const { data: widgetInfo } = useGetWidgetInfoQuery('');
  const banner = widgetInfo?.banner;

  const parsedUrl = useMemo(() => {
    if (banner?.buttonUrl) {
      return banner.buttonUrl.indexOf('http') > 0 ? banner.buttonUrl : `https:\\\\${banner.buttonUrl}`;
    } else return '';
  }, [banner]);

  return banner ? (
    <Card
      className={styles.BannerCard}
      style={{
        backgroundImage: `url(${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${banner?.backGroundFileId})`,
      }}
    >
      <Col className={styles.BannerCol}>
        <Typography className={styles.TextBold}>{banner?.title}</Typography>
        <Typography className={styles.TextSmall}>{banner?.description}</Typography>
        <Link href={parsedUrl} target="_blank" className={styles.TextColored}>
          {banner?.buttonTitle}
        </Link>
      </Col>
    </Card>
  ) : (
    <></>
  );
};
