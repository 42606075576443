import { Col, List } from 'antd';
import { ListProps } from 'src/shared/types';
import React from 'react';
import styles from './styles.module.scss';

export const ListComponent = <T extends Object>({
  data,
  renderFunc,
  containerClass = styles.ListContainer,
}: ListProps<T>) => {
  return (
    <Col className={containerClass}>
      <List className={styles.List} itemLayout="vertical" dataSource={data} renderItem={renderFunc} />
    </Col>
  );
};
