class TokenProviderService {
  private token: string;
  private isAuthenticated: boolean;

  public constructor() {
    this.token = '';
    this.isAuthenticated = false;
  }

  public setToken(token: string) {
    this.isAuthenticated = true;
    this.token = token;
  }

  public getToken(): string {
    return this.token;
  }
}

export const tokenProviderService = new TokenProviderService();
