import { ActionCreators } from 'redux-undo';
import { Button } from 'antd';
import { CaretLeft } from 'src/shared/icons';
import { useAppDispatch } from 'src/hooks';
import React from 'react';
import Typography from 'antd/lib/typography/Typography';
import styles from './styles.module.scss';

interface BackButtonProps {
  className?: string;
  disabled?: boolean;
}

export const BackButton = ({ className, disabled }: BackButtonProps) => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(ActionCreators.undo());
  };

  return (
    <Button className={styles.BackButton + ' ' + className} onClick={handleClick} disabled={disabled}>
      <CaretLeft className={'IconFill'} />
      <Typography className={styles.Label}> Назад</Typography>
    </Button>
  );
};
