import { apiSlice } from './shared/redux/api/apiSlice';
import { appointmentReducer } from './shared/redux/appointment/appointmentSlice';
import { configureStore } from '@reduxjs/toolkit';
import { filterReducer } from './shared/redux/filter/filterSlice';
import { routeReducer } from './shared/redux/routing/routingSlice';
import undoable, { includeAction } from 'redux-undo';

const undoableRouteReducer = undoable(routeReducer, {
  groupBy: (action) => {
    if (action.payload === 'signUp/patientData' || action.payload === 'signUp/finished') {
      return 'patientUpdate';
    }
    return null;
  },
});

const undoableAppointmentReducer = undoable(appointmentReducer, {
  // groupBy: (action) => {
  //   if (
  //     action.type === 'appointment/setDoctor' ||
  //     action.type === 'appointment/setDate' ||
  //     action.type === 'appointment/setMedOrganization'
  //   ) {
  //     return 'DoctorSelect';
  //   }
  //   return null;
  // },
  filter: includeAction(''),
});

const undoableFilterReducer = undoable(filterReducer, { filter: includeAction('') });

export const store = configureStore({
  reducer: {
    router: undoableRouteReducer,
    filter: undoableFilterReducer,
    appointment: undoableAppointmentReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(apiSlice.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
