import { CustomDateTag } from '../CustomDateTag/CustomDateTag';
import { Popconfirm, Row, Tabs, Typography } from 'antd';
import { setChildrenAge, setIsChildren } from 'src/shared/redux/filter/filterSlice';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';

interface AgeToggleProps {
  className?: string;
  containerClassName?: string;
  activeKey?: number;
}

const ages = [
  { key: 0, label: 'до 1 года' },
  { key: 1, label: '1 год' },
  { key: 2, label: '2 года' },
  { key: 3, label: '3 года' },
  { key: 4, label: '4 года' },
  { key: 5, label: '5 лет' },
  { key: 6, label: '6 лет' },
  { key: 7, label: '7 лет' },
  { key: 8, label: '8 лет' },
  { key: 9, label: '9 лет' },
  { key: 10, label: '10 лет' },
  { key: 11, label: '11 лет' },
  { key: 12, label: '12 лет' },
  { key: 13, label: '13 лет' },
  { key: 14, label: '14 лет' },
  { key: 15, label: '15 лет' },
  { key: 16, label: '16 лет' },
  { key: 17, label: '17 лет' },
];

export const AgeToggle = ({ className, containerClassName }: AgeToggleProps) => {
  const [isOpen, setOpen] = useState(false);
  const [label, setLabel] = useState('');
  const dispatch = useAppDispatch();
  const showChildren = useAppSelector((state) => state.filter.present.isChildren);
  const childrenAge = useAppSelector((state) => state.filter.present.childrenAge);

  useEffect(() => {
    setLabel(ages.find((age) => age.key === childrenAge)?.label ?? '');
  }, [childrenAge]);

  const handleDateSelect = useCallback(
    (age: { key: number; label: string }) => {
      dispatch(setIsChildren(true));
      dispatch(setChildrenAge(age.key));
      setOpen(false);
      setLabel(age.label);
    },
    [dispatch],
  );

  const openPopConfirm = () => {
    setOpen(true);
  };

  const handleChange = (activeKey: string) => {
    if (activeKey === '1') {
      dispatch(setIsChildren(false));
      dispatch(setChildrenAge(-1));
      setLabel('');
    }
  };

  const items = useMemo(
    () => [
      { label: `Взрослые`, key: '1' },
      {
        label: (
          <Popconfirm
            open={isOpen}
            placement="bottomRight"
            showCancel={false}
            icon={null}
            title={
              <Row className={styles.AgeRow}>
                {ages.map((age) => (
                  <CustomDateTag key={age.key} onClick={() => handleDateSelect(age)} className={styles.AgeTag}>
                    {age.label}
                  </CustomDateTag>
                ))}
              </Row>
            }
          >
            <Typography onClick={openPopConfirm} className={styles.ChildrenLabel}>
              Дети {label}
            </Typography>
          </Popconfirm>
        ),
        key: '2',
      },
    ],
    [handleDateSelect, isOpen, label],
  );

  return (
    <div className={styles.AgeToggleContainer + ' ' + containerClassName}>
      <Tabs
        className={className}
        type="card"
        items={items}
        onChange={handleChange}
        activeKey={showChildren ? '2' : '1'}
        moreIcon={null}
      />
    </div>
  );
};
