import { Tag } from 'antd';
import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface TagProps {
  children: ReactNode;
  selected?: boolean;
  grey?: boolean;
  className?: string;
}

export const CustomTag = ({ children, selected = false, grey = false, className }: TagProps) => {
  return (
    <Tag className={`${styles.CustomTag} ${selected ? styles.Active : ''} ${grey ? styles.Grey : ''} ${className}`}>
      {children}
    </Tag>
  );
};
