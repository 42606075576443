import './App.scss';
import 'dayjs/locale/ru';
import { AppointmentModule } from './features/AppointmentModule/AppointmentModule';
import { ConfigProvider } from 'antd';
import { CustomSpinner } from './shared/components/CustomSpinner/CustomSpinner';
import { Suspense, useEffect, useState } from 'react';
import { YMaps } from '@pbe/react-yandex-maps';
import { hexToRgb } from './shared/utils';
import dayjs from 'dayjs';
import ruRU from 'antd/lib/locale/ru_RU';
dayjs.locale('ru');

function App() {
  const [themeColor, setColor] = useState('#000000');

  useEffect(() => {
    document.documentElement.style.setProperty('--mila-color-primary', themeColor);
    document.documentElement.style.setProperty('--mila-color-primary-rgb', hexToRgb(themeColor));   
  }, [themeColor]);

  useEffect(() => {
    const buttonElement = document.getElementById('mila-widget-button');
    if (buttonElement) {
      buttonElement.style.setProperty('color', hexToRgb(themeColor));
      buttonElement.style.setProperty('border', `1px solid ${hexToRgb(themeColor)}`);
    }   
  }, [themeColor]);

  const handleThemeChange = (color: string) => {
    setColor(color);
  };
  return (
    <>
      <ConfigProvider
        locale={ruRU}
        theme={{
          token: {
            colorPrimary: themeColor,
            fontFamily: 'Roboto',
          },
        }}
      >
        <YMaps
          query={{
            ns: 'use-load-option',
            load: 'Map,Placemark,control.ZoomControl,control.FullscreenControl,geoObject.addon.balloon,templateLayoutFactory',
            apikey: window.__RUNTIME_CONFIG__.REACT_APP_YANDEX_API_KEY,
          }}
        >
          <Suspense
            fallback={
              <div>
                <CustomSpinner />
              </div>
            }
          >
            <AppointmentModule handleThemeChange={handleThemeChange} />
          </Suspense>
        </YMaps>
      </ConfigProvider>
    </>
  );
}

export default App;
