import { AgeToggle } from 'src/shared/components/AgeToggle/AgeToggle';
import { ClinicList } from './ClinicList';
import { CustomSpinner } from 'src/shared/components/CustomSpinner/CustomSpinner';
import { DoctorList } from './DoctorList';
import { ListComponent } from 'src/shared/components/List/List';
import { Row, Typography } from 'antd';
import { SearchData, WidgetServiceRequestType } from 'src/shared/types';
import { SpecialtiesList } from '../SpecialtiesChoice/SpecialtiesList';
import { SpecialtyUI, mapSpecialties } from '../SpecialtiesChoice/Specialty.mapper';
import Layout from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';

interface SearchProps {
  data: SearchData | undefined;
  isLoading: boolean;
}

const AppSearch = ({ data, isLoading }: SearchProps) => {
  const [onsiteSpecialties, setOnsiteSpecialties] = useState<SpecialtyUI[]>([]);
  const [onlineSpecialties, setOnlineSpecialties] = useState<SpecialtyUI[]>([]);

  useEffect(() => {
    if (data?.onsite) {
      setOnsiteSpecialties(mapSpecialties(data.onsite));
    }
    if (data?.online) {
      setOnlineSpecialties(mapSpecialties(data.online));
    }
  }, [data]);

  return isLoading ? (
    <CustomSpinner />
  ) : data ? (
    <>
      <AgeToggle className={styles.Tabs} containerClassName={styles.TabsContainer} />
      <Layout className={styles.ScrollWrapper}>
        <Layout className={styles.SearchWrapper}>
          {data.onsite.length > 0 && (
            <>
              <Row className={styles.TitleRow}>
                <Typography className={styles.SearchTitle}>Приём в клинике</Typography>
              </Row>
              <SpecialtiesList specialties={onsiteSpecialties} serviceType={WidgetServiceRequestType.OnSite} />
            </>
          )}
          {data.online.length > 0 && (
            <>
              <Row className={styles.TitleRow}>
                <Typography className={styles.SearchTitle}>Онлайн консультации</Typography>
              </Row>
              <SpecialtiesList specialties={onlineSpecialties} serviceType={WidgetServiceRequestType.Online} />
            </>
          )}
          {data.practices.length > 0 && (
            <>
              <Row className={styles.TitleRow}>
                <Typography className={styles.SearchTitle}>Клиники</Typography>
              </Row>
              <ClinicList branches={data.practices} />
            </>
          )}
          {data.doctors.length > 0 && (
            <>
              <Row className={styles.TitleRow}>
                <Typography className={styles.SearchTitle}>Врачи</Typography>
              </Row>
              <DoctorList doctors={data.doctors} />
            </>
          )}
        </Layout>
      </Layout>
    </>
  ) : (
    <>
      <ListComponent containerClass={styles.EmptyList} data={[]} renderFunc={() => null} />
    </>
  );
};

export default AppSearch;
