import { DocPhoto } from 'src/shared/icons';
import { Image } from 'antd';
import React from 'react';
import styles from './styles.module.scss';

interface DocPhotoProps {
  src?: string;
  className?: string;
}

export const DoctorPhoto = ({ src, className }: DocPhotoProps) => {
  return src ? (
    <Image
      className={className + ' ' + styles.PhotoImage}
      preview={false}
      src={`${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${src}`}
    />
  ) : (
    <DocPhoto className={className + ' ' + styles.DefaultPhoto} />
  );
};
