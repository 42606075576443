import { CaretRight } from 'src/shared/icons';
import { ClinicInfo } from 'src/shared/types';
import { Col, Image, List, Row, Typography } from 'antd';
import { ListComponent } from 'src/shared/components/List/List';
import { goToPage } from 'src/shared/redux/routing/routingSlice';
import { setMedOrganization } from 'src/shared/redux/appointment/appointmentSlice';
import { useAppDispatch } from 'src/hooks';
import { useGetWidgetInfoQuery } from 'src/shared/redux/api/apiSlice';
import React from 'react';
import styles from './styles.module.scss';

interface ClinicListProps {
  branches: ClinicInfo[];
}

export const ClinicList = ({ branches }: ClinicListProps) => {
  const { data: widgetInfo } = useGetWidgetInfoQuery('');
  const dispatch = useAppDispatch();

  const handleMenuClick = (item: ClinicInfo) => {
    dispatch(
      setMedOrganization({
        address: item.address.address,
        addressForPatients: item.address.addressForPatients,
        latitude: item.address.latitude,
        longitude: item.address.longitude,
        idMedOrganization: item.idMedOrganization,
        shortName: item.shortName,
        name: item.fullName,
        workingHours: item.common.workingHours,
        nearestSubway: item.address.nearestSubway,
        phoneNumbersForPatients: item.address.phoneNumbersForPatients,
      }),
    );
    dispatch(goToPage('aboutClinic'));
  };
  return (
    <ListComponent
      containerClass={styles.ListContainer}
      data={branches}
      renderFunc={(item) => (
        <List.Item onClick={() => handleMenuClick(item)} className={styles.SearchItem}>
          <Row className={styles.SearchRow}>
            <Col>
              {widgetInfo?.idLogoFile && (
                <Image
                  preview={false}
                  src={`${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${widgetInfo?.idLogoFile}`}
                />
              )}
            </Col>
            <Col className={styles.TextCol}>
              <Typography className={styles.Text}>{item.shortName}</Typography>
              <Typography className={styles.SmallText}>{item.address?.addressForPatients}</Typography>
            </Col>
          </Row>
          <CaretRight className={'IconFill'} />
        </List.Item>
      )}
    />
  );
};
