import { Cancel, Search } from 'src/shared/icons';
import { Input } from 'antd';
import React, { ChangeEvent, useState } from 'react';
import styles from './styles.module.scss';

interface SearchProps {
  handleSearch?: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  className?: string;
  value?: string;
}

export const CustomSearch = ({ handleSearch, placeholder, className, value }: SearchProps) => {
  const [active, setActive] = useState(false);
  const handleKeyTyping = () => {
    setActive(true);
  };

  return (
    <Input
      onKeyDown={handleKeyTyping}
      onBlur={() => setActive(false)}
      prefix={<Search className="IconFill" />}
      placeholder={placeholder}
      onChange={handleSearch}
      defaultValue={value}
      className={`${styles.Search} ${className} ${active ? styles.Active : ''}`}
      allowClear={{ clearIcon: <Cancel className={styles.Cancel + ' IconFill'} /> }}
    />
  );
};
