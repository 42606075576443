import { ActionCreators } from 'redux-undo';
import { BackInCircle, HomeButton, Location, PhoneSmall } from 'src/shared/icons';
import { Button, Row, Typography } from 'antd';
import { Footer } from 'antd/lib/layout/layout';
import { goToPage } from 'src/shared/redux/routing/routingSlice';
import { resetAppointmentData } from 'src/shared/redux/appointment/appointmentSlice';
import { resetFilters } from 'src/shared/redux/filter/filterSlice';
import { useAppDispatch } from 'src/hooks';
import { useGetWidgetInfoQuery } from 'src/shared/redux/api/apiSlice';
import Link from 'antd/es/typography/Link';
import React from 'react';
import styles from './styles.module.scss';

export const MobileFooterComponent = ({ page }: { page: string }) => {
  const dispatch = useAppDispatch();
  const { data: widgetInfo } = useGetWidgetInfoQuery('');

  const handleGoHome = () => {
    dispatch(ActionCreators.jumpToPast(0));
    dispatch(resetAppointmentData());
    dispatch(resetFilters());
  };

  const handleGoBack = () => {
    dispatch(ActionCreators.undo());
  };

  return (
    <Footer className={styles.Footer}>
      <Row className={styles.FooterRow}>
        <Button disabled={page === 'home'} className={styles.FooterButton} onClick={handleGoBack}>
          <BackInCircle className="IconStroke" />
          <Typography className={styles.Text}>Назад</Typography>
        </Button>
        <Button disabled={page === 'home'} className={styles.FooterButton} onClick={handleGoHome}>
          <HomeButton className="IconFill" />
          <Typography className={styles.Text}>В начало</Typography>
        </Button>
        <Button className={styles.FooterButton} onClick={() => dispatch(goToPage('addresses'))}>
          <Location className="IconFill" />
          <Typography className={styles.Text}>Адреса</Typography>
        </Button>
        <Link href={`tel:${widgetInfo?.phoneNumber}`} className={styles.FooterButton}>
          <PhoneSmall className="IconFill" />
          <Typography className={styles.Text}>Позвонить</Typography>
        </Link>
      </Row>
    </Footer>
  );
};
