import { Doctor, DoctorUI } from './doctor.types';
import { FormInstance } from 'antd';
import { ReactNode } from 'react';
import { Specialty } from './specialty.types';
export interface ListItem {
  key: string;
  title: string;
  icon?: ReactNode;
}

export interface ListProps<T> {
  data: T[];
  renderFunc: (val: T) => ReactNode;
  containerClass?: string;
}

export interface DoctorProps {
  doctor: DoctorUI;
  selectedBranchId?: string;
  specialty: Specialty;
}

export interface PropsWithChildren {
  children: ReactNode;
}

export interface Patient {
  idPatient: string;
  name: string;
  middleName: string;
  surname: string;
  birthDay: string;
  gender?: Gender;
  phone: string;
  email: string;
}

export enum Gender {
  Female = 0,
  Male = 1,
}

export interface PatientData {
  idPatient: string;
  name: string;
  middleName: string;
  surname: string;
  birthDay: Date;
  gender?: Gender;
  phone: string;
  email: string;
  getAgreement: boolean;
}

export interface AppointmentData {
  idPatient: string;
  idMedOrganization: string;
  idAppointment?: string;
  idMilaDoctor: string;
  idFerSpeciality: string;
  doctorSpecialization: string;
  idSpecialityService: string;
  cost: string;
  visitStartDate?: string;
  visitEndDate?: string;
}

export interface PhoneCallData {
  name: string;
  phoneNumber: string;
  whenCallback: string;
}

export interface CalendarData {
  idPatient: string;
  appointmentId?: string;
}

export interface AdditionalEmailData {
  idPatient: string;
  appointmentId: string;
  email: string;
}

export interface WidgetInfo {
  backgroundColor: string;
  branches: Branch[];
  phoneNumber: string;
  title: string;
  idLogoFile: string;
  availableServiceTypes: { item1: WidgetServiceRequestType; item2: string }[];
  banner: {
    backGroundFileId: string;
    buttonTitle: string;
    buttonUrl: string;
    description: string;
    title: string;
  };
}

export interface Branch {
  address: string;
  idMedOrganization: string;
  name: string;
  shortName: string;
  latitude: number;
  longitude: number;
  nearestSubway: string;
  addressForPatients: string;
  workingHours: string;
  phoneNumbersForPatients: string[];
}

export interface BranchForMap extends Branch {
  earliestDate?: Date;
  adultGeneral: number;
  childrenGeneral: number;
  nearestDataDoctorName: string;
}

export interface ClinicInfo {
  idMedOrganization: string;
  fullName: string;
  shortName: string;
  common: {
    description: string;
    firstPhotoFileId: string;
    secondPhotoFileId: string;
    timeZone: string;
    workingHours: string;
  };
  address: {
    address: string;
    addressForPatients: string;
    district: string;
    howToGetHereDescription: string;
    latitude: number;
    longitude: number;
    nearestSubway: string;
    parkingDescription: string;
    subwayDistance: string;
    phoneNumbersForPatients: string[];
  };
}

export interface PatientFormProps {
  patient?: Patient;
  form: FormInstance;
}

export interface SearchData {
  atHome: string;
  diagnostic: string;
  online: Specialty[];
  doctors: Doctor[];
  practices: ClinicInfo[];
  onsite: Specialty[];
}

export interface Documents {
  termsOfService: {
    staticFileId: string;
    name: string;
  };
  dataProcessingAgreement: {
    staticFileId: string;
    name: string;
  };
}

export enum WidgetServiceRequestType {
  OnSite = 0,
  AtHome = 1,
  Online = 2,
}

export interface WidgetSpecialitiesArgsType {
  serviceRequestType: WidgetServiceRequestType;
  serveAge?: number;
}

export interface SearchArgsType {
  data: string;
  serveAge?: number;
}

export interface WhiteLabelInfo {
  displayName: string;
  idLogoFile: string;
  linksColor: string;
  idIconFile: string;
  headersColor: string;
  domain: string;
  isServiceEnabled: boolean;
  medCardUpdatesColor: {
    textColor: string;
    backgroundColor: string;
  };
  nearestAppointmentColor: {
    textColor: string;
    backgroundColor: string;
  };
  mainButtonColor: {
    textColor: string;
    backgroundColor: string;
  };
}
